<template>
  <!-- 菜单管理页面 -->
	<div class="box">
		<div>
			<!-- 顶部 -->
			<div class="header">
				<div class="title">提现</div>
			</div>
			<div class="tableStyle">
				<a-table :columns="columns" :data-source="tabledData" :pagination="false" :loading="tableLoading" :row-key="record => record.id" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onChange }" bordered>
                    <span slot="status" slot-scope="status, record">
                        <a-badge v-if="status == 0" color="#67C23A"/>
                        <a-badge v-if="status == 1" color="#108ee9"/>
                        <a-badge v-else-if="status == 2" color="#F56C6C"/>
                        <span>{{ record.status == 0 ? '可提现' : record.status == 2 ? '提现中' : record.status == 2 ? '已提现' :  '--' }}</span>
                    </span>
                </a-table>
                <div class="btn">
					<a-button @click="()=>$router.go(-1)" class="margin_right10">取消</a-button>
			        <a-button type="primary" @click="onSubmit">确定</a-button>
				</div>
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
import { message, Modal } from "ant-design-vue";
import { CandrawcashList,ApplyDrwcash } from "@/request/api/orderManage";
export default {
	created() {
		this.candListFn();
	},
	data() {
        return {
			tableLoading: true, // 表格的loading图
			selectedRowKeys:[],
			// 全部/在途/已到账表格
            columns: [
                {
					title: "时间",
					dataIndex: "datetime",
					width:"33.3%",
					key: "datetime",
				},
				{
					title: "金额",
					dataIndex: "amount",
					width:"33.3%",
					key: "amount",
				},
				{
					title: "状态",
					dataIndex: "status",
					width:"33.3%",
                    key: "status",
                    scopedSlots: { customRender: "status" }
				},
			],
			tabledData: [],
		};
	},
	methods: {
        // 获取表格id
        onChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
        },
		
		// 请求列表数据
		candListFn() {
			CandrawcashList().then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false;
                    this.tabledData = data;
                } else {
                    this.tableLoading = false;
                }
			});
        },

        // 点击确定
        onSubmit () {
            let self = this
            if (this.selectedRowKeys.length < 1) {
                message.error('请选择要提现的数据')
                return false
            }
            Modal.confirm({
                title: "确定后将发起提现请求",
                okText: "确定",
                okType: "primary",
                cancelText: "取消",
                onOk() {
                    ApplyDrwcash({ ids: self.selectedRowKeys }).then((res) => {
                        if (res.code === 200) {
                            self.selectedRowKeys = []
                            self.tabledData = []
                            self.tableLoading = true;
                            message.success("已申请提现！请耐心等待");
                            self.candListFn()
                        }
                    });
                },
            });
        }
	},
};
</script>
 
<style lang="less" scoped>
.box {
  min-height: 100%;
  // background-color: #fff;
  padding-bottom: 56px;
  .header{
    width: 100%;
    background-color: #fff;
    .title{
      padding: 14px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
    }
	.content{
		display: flex;
		justify-content: space-between;
		.numBox{
			display: flex;
			flex-direction: column;
			padding: 14px 16px 52px;
			.num{
				font-size: 32px;
				font-family: DINAlternate-Bold, DINAlternate;
				font-weight: bold;
				color: #000000;
			}
			.size{
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #666666;
			}
		}
	}
  }
  .tableStyle{
	background-color: #fff;
	padding: 16px;
    .btn{
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
    }
	.page{
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
	}
  }
}
</style>